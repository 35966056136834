import { useFlagsmith } from 'flagsmith/react';
import { useEffect } from 'react';
import { isNonEmptyString, isRecord } from '~/utilities/type-guards';
import { useFlagsmithV3IdentityAndTraitsLoaderQuery } from './__gql/FlagsmithV3IdentityAndTraitsLoader.gql.gen';

export const FlagsmithV3IdentityAndTraitsLoader = ({
  setLoading,
  skip,
}: {
  setLoading: (loading: boolean) => void;
  skip: boolean;
}): null => {
  const flagsmithInstance = useFlagsmith();
  const { data, loading } = useFlagsmithV3IdentityAndTraitsLoaderQuery({ skip });

  const flagsmithId = data?.currentAccount?.flagsmithId;
  const flagsmithIdentityData = data?.currentAccount?.flagsmithIdentityData;

  useEffect(() => {
    const updateFlagsmithIdentity = async () => {
      if (isNonEmptyString(flagsmithId) && isRecord(flagsmithIdentityData)) {
        const isValidIdentityData = (
          identityData: Record<string, unknown>,
        ): identityData is Record<string, boolean | number | string | null> => {
          return Object.values(identityData).every(
            (value) =>
              typeof value === 'string' ||
              typeof value === 'number' ||
              typeof value === 'boolean' ||
              value === null,
          );
        };

        if (isValidIdentityData(flagsmithIdentityData)) {
          await flagsmithInstance.identify(flagsmithId, flagsmithIdentityData);
        }
      } else if (!loading && !skip) {
        // We're in a context like gladmin where there is no currentAccount flagsmithId.
        // Note: In the serverside sdk, flagsmith allows identifying with null.
        // This is not supported in the client side sdk, so we'll use a meaningless string.
        // Please do not use this meaningless string within the flagsmith UI.
        await flagsmithInstance.identify('default-flags');
      } else {
        // do nothing, we're still awaiting the query for the flagsmithId;
      }
      setLoading(false);
    };

    void updateFlagsmithIdentity();
  }, [flagsmithId, flagsmithIdentityData, flagsmithInstance, setLoading, loading, skip]);

  return null;
};
