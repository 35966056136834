import type { Nullable } from '~/utilities/type-guards';
import { isNotNil, isNotUndefined, isString } from '~/utilities/type-guards';
import { reportError } from '~/utilities/errorReporting';
import type { Pillar, Product } from 'types/wistia';
import type { UnknownRecord } from 'type-fest';

// Since this can be accomplished in CSS, try to utilize CSS
// for any capitaliztion needs before resorting to this
// function - Burns
export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

// Parameters Type for the two tryCatch functions below - Burns
export type TryCatchParameters<R, EF> = {
  fn: (...args: unknown[]) => R;
  errorFn: EF;
  reportErrorContext?: {
    [key: string]: unknown;
    product: Product;
    pillar: Pillar;
  };
  finallyFn?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noOpFn = (): void => {};
export type NoOpFn = typeof noOpFn;

export const noOpFnReturningNull = (): null => null;
export type NoOpFnReturningNull = typeof noOpFnReturningNull;

export const asyncNoOpFn = async (): Promise<void> => {
  // do nothing
};
export type AsyncNoOpFn = typeof asyncNoOpFn;
export type AsyncOrSyncNoOpFn = AsyncNoOpFn | NoOpFn;

/**
 * Wraps a synchronous function that can possibly
 * throw an error in a try/catch.
 *
 * If the function does throw an error, allow
 * the caller to optionally run functionality
 * around the error and log it out to the console
 *
 * @param fn - The function needing to be run in a try/catch
 * @param errorFn - A callback function to allow for extra functionality to happen with the error
 * @param reportErrorContext - An optional object that gives extra error context for error reporting
 */
export const tryCatch = <R, EF extends (error: unknown) => unknown>({
  fn,
  errorFn,
  reportErrorContext,
  finallyFn,
}: TryCatchParameters<R, EF>): R | ReturnType<EF> => {
  try {
    return fn();
  } catch (error: unknown) {
    if (error instanceof Error && isNotUndefined(reportErrorContext)) {
      reportError(error, reportErrorContext);
    }

    // Because we are restricting the type of the error function
    // to a function type, we can confidently type assert that the
    // return of this function is the return type of the passed
    // in error function. More info here on type asserting for
    // `unknown` types: https://devblogs.microsoft.com/typescript/announcing-typescript-3-0-rc-2/#the-unknown-type
    // - Burns
    return errorFn(error) as ReturnType<EF>;
  } finally {
    if (isNotNil(finallyFn)) {
      finallyFn();
    }
  }
};

/**
 * Wraps an asynchronous function that can possibly
 * throw an error in a try/catch.
 *
 * If the function does throw an error, allow
 * the caller to optionally run functionality
 * around the error and log the error to the console
 *
 * @param fn - The function needing to be run in a try/catch
 * @param errorFn - A callback function to allow for extra functionality to happen with the error
 * @param reportErrorContext - An optional object that gives extra error context for error reporting
 * @returns
 */
export const tryCatchAsync = async <R, EF extends (error: unknown) => unknown>({
  fn,
  errorFn,
  reportErrorContext,
  finallyFn,
}: TryCatchParameters<Promise<R>, EF>): Promise<R | ReturnType<EF>> => {
  try {
    return await fn();
  } catch (error: unknown) {
    if (error instanceof Error && isNotUndefined(reportErrorContext)) {
      reportError(error, reportErrorContext);
    }

    // Because we are restricting the type of the error function
    // to a function type, we can confidently type assert that the
    // return of this function is the return type of the passed
    // in error function. More info here on type asserting for
    // `unknown` types: https://devblogs.microsoft.com/typescript/announcing-typescript-3-0-rc-2/#the-unknown-type
    // - Burns
    return errorFn(error) as ReturnType<EF>;
  } finally {
    if (isNotNil(finallyFn)) {
      finallyFn();
    }
  }
};

/**
 * Checks if a string or array of strings includes a given string
 *
 * @param haystack - An array of strings to search through
 * @param needle - The string to search for
 * @param reportErrorContext - An optional object that gives extra error context for error reporting
 */
export const includesString = <T extends string>(
  haystack: T | T[] | readonly T[],
  needle: string,
): boolean => haystack.includes(needle as T);

/**
 * The format of an SRT timestamp is HH:MM:SS,mmm
 * This function converts that timestamp to seconds
 * @param timestamp
 * @returns number of seconds
 */
export const srtTimestampToSeconds = (timestamp: string): number => {
  const [hours, minutes, seconds, milliseconds] = timestamp.split(/[:,]/).map(Number);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
};

/**
 * Pluralizes a word based on the count. This is temporary as the pluralize package
 * doesn't have ts defintions.
 *
 * @param count
 * @param word
 * @returns string
 */
export const pluralize = (count: number, word: string): string => {
  if (count === 1) {
    return word;
  }

  return `${word}s`;
};

/**
 * Removes a property from an object and returns the object
 *
 * @param key - The key of the property to remove
 * @param object - The object to the property will be removed from
 * @returns The object with the property removed
 */
export const removeObjectProperty = <O extends UnknownRecord, K extends keyof O = keyof O>(
  key: K,
  object: O,
): Omit<O, K> => {
  const { [key]: removedOption, ...rest } = object;

  return rest;
};

export const isEmail = (value: string): boolean => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

/**
 * Returns the value passed in
 *
 * @param value - The value to return
 * @returns The value passed in
 *
 * @example
 * const value = identity(1); // 1
 */
export const identity = <T>(value: T): T => value;

/**
 * Gets data from localStorage
 *
 * @param key - The key to get the data from
 * @returns The data from localStorage or null if there is an error
 *
 * @example
 * const data = getLocalStorageData('myKey');
 */
export const getLocalStorageData = (key: string): Nullable<string> =>
  tryCatch({
    fn: () => localStorage.getItem(key),
    errorFn: () => null,
  });

/**
 * Sets data in localStorage
 *
 * @param key - The key to set the data to
 * @param value - The value to set in localStorage
 *
 * @example
 * setLocalStorageData('myKey', 'myValue');
 */
export const setLocalStorageData = (key: string, value: unknown): void =>
  tryCatch({
    fn: () => localStorage.setItem(key, JSON.stringify(value)),
    errorFn: noOpFn,
  });

/**
 * Parses unknown data
 *
 * @param data - The json string to parse
 * @param defaultValue - The default value to return if there is an error
 * @returns The parsed data from localStorage or the defaultValue if there is an error
 *
 * @example
 * const data = parseUnknownJsonData('{}', []);
 */
export const parseUnknownJsonData = <T, D>(jsonString: unknown, defaultValue: D): D | T =>
  tryCatch({
    fn: () => (isString(jsonString) ? (JSON.parse(jsonString) as T) : defaultValue),
    errorFn: () => defaultValue,
  });

/**
 * Parses data from localStorage
 *
 * @param key - The key to get the data from
 * @param defaultValue - The default value to return if there is an error
 * @returns The parsed data from localStorage or the defaultValue if there is an error
 *
 * @example
 * const data = parseLocalStorageData('myKey', []);
 */
export const parseLocalStorageData = <T, D>(key: string, defaultValue: D): D | T =>
  parseUnknownJsonData<T, D>(getLocalStorageData(key), defaultValue);
